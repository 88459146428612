.Testimonaoals{
    display: flex;
    gap: 2rem;
    padding:0 2rem;
}
.left-t{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 3rem;
    color: #fff;
    text-transform: capitalize;

}

.left-t>:nth-child(1){
    color: var(--orange);
    font-size: 1.5rem;
    font-weight: bold;

}
.left-t>:nth-child(2),
.left-t>:nth-child(3)
{ 
    font-weight: bold;
    font-size: 4rem;
} 
.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    line-height: 40px;
}

.right-t{
    flex : 1 ;
    position: relative;
}
.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}

.right-t>:nth-child(1)
{
    position: absolute;
    width: 17rem;height: 20rem;
    border: 2px solid orange;
    right:9rem;
    top: 0.9rem;

}
.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    top: 4em;
    right: 7rem;
    background: var(--planCard);
    
}
.arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 3rem;
}

.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}