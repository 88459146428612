.Hero{
    display: flex;
    justify-content: space-between;
}
.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.right-h{
    flex: 1;
    position: relative;
    background-color: var(--orange);
}

.the-add{
    margin-top: 4rem;
    background-color:#363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
}
.the-add>span{
    z-index: 2;
} 

.the-add-div{
    position: absolute;
    background-color:var(--orange);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
   top: 50%;
   transform: translateY(-50%);
    z-index: 1;
    opacity: 0.7;
}
   
   
    
    

    

.hero-text{
    color: #fff;
    display:flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 5rem;
    text-transform: uppercase;
    font-weight:bold;
    text-overflow: inherit;
}
.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

.figures{
    display: flex;
    gap: 2rem;
}
.figures>div{
    display: flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1){
    color: #fff;
    font-size: 2rem;
}
.figures>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform:uppercase;
}

.hero-buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.hero-buttons>:nth-child(1){
    color: #fff;
    background-color:var(--orange);
    width: 8rem;
}
.hero-buttons>:nth-child(2){
    color: #fff;
    background-color:transparent;
    width: 8rem;
    border: 2px solid var(--orange);
}

.right-h>.btn{
    position: absolute;
    right: 3rem;top: 2rem;color: black;
}

.heart-rate{
    display: flex;
    flex-direction: column;
    background-color: var(--darkGrey);
    gap: 1rem;
    width: fit-content;
    padding: 1rem;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
}

.heart-rate>img{
    width: 2rem;
}

 .heart-rate>span:nth-of-type(1){
    color: var(--gray);
 }
 .heart-rate>span:nth-of-type(2){
    color: white;
    font-size: 1.5rem;
 }

.hero-image{
    position: absolute;
    top: 10rem;
    right: 10rem;
    width: 23rem;
}
.hero-image-back{
    position: absolute;
    top: 4rem;
    right: 20rem;
    z-index: -1;
    width: 15rem;
}

.Calories{
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 7px ;
    padding: 1rem;
    width: max-content;
    position: absolute;
    top: 34rem;
    right: 28rem;
}
.Calories.img{
    width: 3rem;
}
.Calories  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.Calories > div >:nth-child(1){
    color: var(--gray);
}
.Calories > div >:nth-child(2){
    color:#fff;
    font-size: 1.5rem;
}

.Hero-blur{
    width: 22rem;
    height: 38rem;
    left: 0;
}