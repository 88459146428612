.plans-container {
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
  }
  .plan{
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  
  .plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }
  
  .plan-card { /* Updated to plan-card to match JSX */
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: #fff;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
  }
  
  .plan-card:nth-child(2) { /* Updated to plan-card to match JSX */
    background: var(--planCard);
    transform: scale(1.1);
  }


 .plan-card:nth-child(3){
    font-size: 1rem;
    font-weight: bold;

 } 
 .plan>:nth-child(5){
    font-size: 0.8rem;
 }
 .features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
 }
 
 .feature{
    display: flex;
    align-items: center;
    gap: 1rem;
 }
 .feature>img{
    width: 1rem;
 }

   .plans>:nth-child(2)>svg{
    fill: #fff;
   }
   .plans>:nth-child(2)>button{
    color: orange;
   }
   .plan-blur-1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}

.plan-blur-2{
    width: 32rem;
    height: 23rem;
    top: 8rem;
    right: 0rem;
}