.header{
    display: flex;
    justify-content: space-between;
   
}

.logo{
    width: 10rem;
    height: 3rem;
}


.un-list{
    display: flex;
    list-style: none;
    color: #fff;
    gap: 3rem;
    justify-content: center;
    align-items: center;
}

.un-list>li:hover{
    cursor: pointer;
    color: var(--orange);
    cursor:pointer;
}